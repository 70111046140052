<template>
<div class="kt-form kt-form--label-right">
    <div class="kt-portlet__body">
        <div class="kt-widget5">
            <div class="kt-widget5__item">
                <div class="kt-widget5__content w-100">
                    <div class="kt-widget5__section w-100">
                        <a href="#" class="kt-widget5__title">
                            App Notifications
                        </a>
                        <p class="kt-widget5__desc">
                            Change In-App notification settings
                        </p>
                        <div class="kt-widget5__info mt-4">
                            <div class="form-group row ml-2">
                                <div>
                                    <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                        <label>
                                            <input
                                                v-model="messageNotificationsEnabled"
                                                type="checkbox"
                                                @change="changeMessageNotifications"
                                            >
                                            <span />
                                        </label>
                                    </span>
                                </div>
                                <label class="col-form-label">Receive In-App message notifications to this device</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-widget5__content" />
            </div>
            <div class="kt-widget5__item">
                <div class="kt-widget5__content w-100">
                    <div class="kt-widget5__section w-100">
                        <a href="#" class="kt-widget5__title">
                            Email Subscriptions
                        </a>
                        <p class="kt-widget5__desc">
                            Change SyncGrades email notification subscription settings
                        </p>
                        <div class="kt-widget5__info mt-4">
                            <div class="row w-100">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input
                                                v-model="email"
                                                type="email"
                                                class="form-control"
                                                placeholder="Email"
                                                :disabled="true"
                                            >
                                            <div class="input-group-append">
                                                <span class="input-group-text">
                                                    <label class="kt-checkbox kt-checkbox--single kt-checkbox--primary">
                                                        <input
                                                            v-model="emailSubscribed"
                                                            type="checkbox"
                                                            :disabled="emailComplaint"
                                                            @change="changeSubscription"
                                                        >
                                                        <span />
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span
                                    v-if="emailComplaint"
                                    class="form-text text-muted kt-font-danger kt-font-bolder ml-2 mt-2"
                                >Email has been marked as complaint by Email Providers</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-widget5__content" />
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import * as network from '../../network';
import Types from '../../store/Types';

export default {
    name: 'CommunicationSettings',
    data() {
        return {
            saving: false,
            email: null,
            emailSubscribed: false,
            emailComplaint: false,
            messageNotificationsEnabled: true,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        schoolStaffId() {
            const { user } = this;
            const { school } = user;
            const { schoolStaffId } = school;
            return schoolStaffId || null;
        },
        teacher() {
            const { schoolStaffId } = this;
            if (!schoolStaffId) return null;
            const { teachers } = this.$store.state.database;
            return teachers.find((t) => t.schoolStaffId === schoolStaffId) || null;
        },
    },
    mounted() {
        const v = this;
        v.messageNotificationsEnabled = this.$store.state.settings.communication.messageNotificationsEnabled;

        const {
            user, schoolStaffId, teacher,
        } = v;
        if (schoolStaffId) {
            this.email = teacher.schoolEmail;
        } else {
            this.email = user.mappedUserName || user.userName;
        }

        const { school } = user;
        const { schoolId, schoolTermId } = school;
        const params = {
            url: {
                schoolId,
                schoolTermId,
                email: this.email,
            },
        };
        network.communication.getSubscribedStatus(params, (err, res) => {
            if (err) return v.showError(err);
            const { unsubscribed } = res;
            if (unsubscribed) {
                v.emailSubscribed = unsubscribed.emailSubscribed;
                v.emailComplaint = unsubscribed.emailComplaint;
                if (v.emailComplaint) {
                    v.emailSubscribed = false;
                }
            } else {
                v.emailSubscribed = true;
                v.emailComplaint = false;
            }
        });
    },
    methods: {
        changeSubscription() {
            const v = this;
            if (v.saving) return;
            v.saving = true;

            const { user, emailSubscribed, email } = v;
            const { school } = user;
            const { schoolId, schoolTermId } = school;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    email,
                },
                body: {
                    emailSubscribed,
                },
            };

            network.communication.changeEmailSubscription(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);
                const { status } = res;
                if (status === 'success') {
                    v.emailSubscribed = emailSubscribed;
                }
            });
        },
        changeMessageNotifications() {
            const { messageNotificationsEnabled } = this;
            this.$store.commit(Types.mutations.SAVE_SETTING_MESSAGE_NOTIFICATIONS_ENABLED, messageNotificationsEnabled);
        },
    },
};
</script>

<style scoped>

</style>
