var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-form kt-form--label-right" }, [
    _c("div", { staticClass: "kt-portlet__body" }, [
      _c("div", { staticClass: "kt-widget5" }, [
        _c("div", { staticClass: "kt-widget5__item" }, [
          _c("div", { staticClass: "kt-widget5__content w-100" }, [
            _c("div", { staticClass: "kt-widget5__section w-100" }, [
              _c(
                "a",
                { staticClass: "kt-widget5__title", attrs: { href: "#" } },
                [_vm._v(" App Notifications ")]
              ),
              _c("p", { staticClass: "kt-widget5__desc" }, [
                _vm._v(" Change In-App notification settings "),
              ]),
              _c("div", { staticClass: "kt-widget5__info mt-4" }, [
                _c("div", { staticClass: "form-group row ml-2" }, [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "kt-switch kt-switch--outline kt-switch--icon kt-switch--success",
                      },
                      [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.messageNotificationsEnabled,
                                expression: "messageNotificationsEnabled",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.messageNotificationsEnabled
                              )
                                ? _vm._i(
                                    _vm.messageNotificationsEnabled,
                                    null
                                  ) > -1
                                : _vm.messageNotificationsEnabled,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.messageNotificationsEnabled,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.messageNotificationsEnabled =
                                          $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.messageNotificationsEnabled = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.messageNotificationsEnabled = $$c
                                  }
                                },
                                _vm.changeMessageNotifications,
                              ],
                            },
                          }),
                          _c("span"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("label", { staticClass: "col-form-label" }, [
                    _vm._v(
                      "Receive In-App message notifications to this device"
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "kt-widget5__content" }),
        ]),
        _c("div", { staticClass: "kt-widget5__item" }, [
          _c("div", { staticClass: "kt-widget5__content w-100" }, [
            _c("div", { staticClass: "kt-widget5__section w-100" }, [
              _c(
                "a",
                { staticClass: "kt-widget5__title", attrs: { href: "#" } },
                [_vm._v(" Email Subscriptions ")]
              ),
              _c("p", { staticClass: "kt-widget5__desc" }, [
                _vm._v(
                  " Change SyncGrades email notification subscription settings "
                ),
              ]),
              _c("div", { staticClass: "kt-widget5__info mt-4" }, [
                _c("div", { staticClass: "row w-100" }, [
                  _c("div", { staticClass: "col-lg-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "input-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "email",
                            placeholder: "Email",
                            disabled: true,
                          },
                          domProps: { value: _vm.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.email = $event.target.value
                            },
                          },
                        }),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c("span", { staticClass: "input-group-text" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "kt-checkbox kt-checkbox--single kt-checkbox--primary",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.emailSubscribed,
                                      expression: "emailSubscribed",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    disabled: _vm.emailComplaint,
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.emailSubscribed)
                                      ? _vm._i(_vm.emailSubscribed, null) > -1
                                      : _vm.emailSubscribed,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.emailSubscribed,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.emailSubscribed = $$a.concat(
                                                [$$v]
                                              ))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.emailSubscribed = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.emailSubscribed = $$c
                                        }
                                      },
                                      _vm.changeSubscription,
                                    ],
                                  },
                                }),
                                _c("span"),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm.emailComplaint
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "form-text text-muted kt-font-danger kt-font-bolder ml-2 mt-2",
                        },
                        [
                          _vm._v(
                            "Email has been marked as complaint by Email Providers"
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "kt-widget5__content" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }